<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Задачи</v-card-title>
        <v-card-actions style="padding: 0 16px;flex-direction: column">
          <v-row style="width: 100%" >
            <v-col class="pt-0 pt-md-7 col-12 col-md-2">
              <v-btn @click="editTask()">Создать</v-btn>
            </v-col>
            <v-col  class="col-12 col-md-2">
              <label>Для кого</label>
              <v-select
                  @change="getTasks()"
                  v-model="filters.user_id"
                  item-text="login"
                  item-value="n_id"
                  multiple
                  :items="$store.state.user.managers"
                  outlined
              >
                <v-list-item
                    slot="prepend-item"
                    ripple
                    @click="toggle"
                >
                  <v-list-item-action>
                    <v-icon :color="filters.user_id.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Выбрать всех</v-list-item-title>
                </v-list-item>
                <v-divider
                    slot="prepend-item"
                    class="mt-2"
                />
              </v-select>
            </v-col>
            <v-col  class="col-12 col-md-2">
              <label>Статус</label>
              <v-select
                  @change="getTasks()"
                  v-model="filters.status"
                  multiple
                  item-text="name"
                  item-value="value"
                  :items="[{name:'Не выполнено', value: '0'},{name:'Выполнено', value: '1'}]"
                  outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row
              style="width: 100%"
              class="overflow-x-auto flex-nowrap flex-md-wrap">
            <v-col class="col-auto col-md-3 d-flex flex-column">
              <label>Фильтровать по</label>
              <v-btn @click="filters.dateType = ((Number(filters.dateType)||0)+1)%2;getTasks()">
                {{ ['Сроку выполнения', 'Дате создания'][Number(filters.dateType) || 0] }}
              </v-btn>
            </v-col>
            <v-col class="col-auto col-md-1">
              <v-btn style="width: 100%;background: #eee !important;margin-top: 20px;"
                     @click="filters.date = [$moment().add(-1, 'days').format('YYYY-MM-DD'), $moment().add(-1, 'days').format('YYYY-MM-DD')];getTasks()">
                Вчера
              </v-btn>
            </v-col>
            <v-col class="col-auto col-md-1">
              <v-btn style="width: 100%;background: #eee !important;margin-top: 20px;"
                     @click="filters.date = [$moment().format('YYYY-MM-DD'), $moment().format('YYYY-MM-DD')];getTasks()">
                Сегодня
              </v-btn>
            </v-col>
            <v-col class="col-auto col-md-1">
              <v-btn style="width: 100%;background: #eee !important;margin-top: 20px;"
                     @click="filters.date = [$moment().add(1, 'days').format('YYYY-MM-DD'), $moment().add(1, 'days').format('YYYY-MM-DD')];getTasks()">
                Завтра
              </v-btn>
            </v-col>
            <v-col class="col-auto col-md-1">
              <v-btn style="width: 100%;background: #eee !important;margin-top: 20px;"
                     @click="filters.date = [$moment().add(2, 'days').format('YYYY-MM-DD'), $moment().add(2, 'days').format('YYYY-MM-DD')];getTasks()">
                Послезавтра
              </v-btn>
            </v-col>
            <v-col class="col-auto col-md-1">
              <v-btn style="width: 100%;background: #eee !important;margin-top: 20px;"
                     @click="filters.date = [$moment().startOf('isoWeek').format('YYYY-MM-DD'), $moment().endOf('isoWeek').format('YYYY-MM-DD')];getTasks()">
                Эта неделя
              </v-btn>
            </v-col>
            <v-col class="col-auto col-md-2 d-none d-md-block">
              <label>Период</label>
              <v-menu
                  ref="menu"
                  transition="scale-transition"
                  left
                  :close-on-content-click="false"
                  bottom
                  nudge-bottom="35"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.outputRange(filters.date)"
                      readonly
                      v-bind="attrs"
                      style="width: 100%"
                      v-on="on"
                  />
                  <v-btn
                      icon
                      @click.stop="emptyDate"
                      color="grey"
                      :style="filters.date.length ? '' : 'display: none'"
                      style="position: absolute;right: 5px;top: 28px;z-index: 100;"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="filters.date"
                    no-title
                    scrollable
                    @change="getTasks()"
                    range
                    first-day-of-week="1"
                    locale="ru"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row style="width: 100%" class="d-flex d-md-none">
            <v-col class="col-8">
              <label>Период</label>
              <v-menu
                  ref="menu"
                  transition="scale-transition"
                  left
                  :close-on-content-click="false"
                  bottom
                  nudge-bottom="35"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.outputRange(filters.date)"
                      readonly
                      v-bind="attrs"
                      style="width: 100%"
                      v-on="on"
                  />
                  <v-btn
                      icon
                      @click.stop="emptyDate()"
                      color="grey"
                      :style="filters.date.length ? '' : 'display: none'"
                      style="position: absolute;right: 5px;top: 28px;z-index: 100;"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="filters.date"
                    no-title
                    scrollable
                    @change="getTasks()"
                    range
                    first-day-of-week="1"
                    locale="ru"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="rows"
              :headers="taskHeader"
              item-key="id"
              :loading="loading_task"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Задач нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item}">
              <tr style="cursor: pointer" @click="editTask(item.id)">
                <td>
                  <span v-if="item.user_id === '-1'">Всем</span>
                  <span v-else>{{ $store.state.user.managers.find(el => el.n_id === item.user_id).login }}</span>
                </td>
                <td>
                  <v-chip small :color="priorityColors[Number(item.priority)]">
                    {{ priority.find(el => el.value === item.priority).text }}
                  </v-chip>
                </td>
                <td>{{ item.title }}</td>
                <td v-on:click.stop="Number(item.client_id) ? $eventBus.$emit('openClient', item.client_id) : editTask(item.id)"
                    :style="Number(item.client_id) ? 'cursor:pointer' : ''">
                  {{ item.client ? item.client.phone + ' ' + item.client.name : '' }}
                </td>
                <td>
                  <router-link :to="`/orders/${item.order.n_id}`" v-if="item.order">
                    {{ $root.dateToRus(item.order.datetimecleanObj.date) }} {{ item.order.datetimecleanObj.time }},
                    {{ item.order.address.street }} д{{ item.order.address.house }}
                  </router-link>
                </td>
                <td>
                  {{
                    item.datetime_toObj ? $root.dateToRus(item.datetime_toObj.date) + ' ' + item.datetime_toObj.time : ''
                  }}
                </td>
                <td>{{ statuses.find(el => el.value === item.status).text }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: "Tasks",
  components: {
  },
  data() {
    return {
      rows: [],
      priorityColors: ['#2eb85c', '#f9b115', '#e55353'],
      filters: {
        user_id: [],
        status: ['0'],
        date: [],
      },
      menu: false,
      taskHeader: [
        {text: 'Для кого', value: 'manager'},
        {text: 'Приоритет', value: 'priority'},
        {text: 'Задача', value: 'title'},
        {text: 'Клиент', value: 'client'},
        {text: 'Заказ', value: 'order'},
        {text: 'Срок выполнения', value: 'datetime'},
        {text: 'Статус', value: 'status'},
      ],
      loading_task: true,
      statuses: [
        {value: '0', text: 'Не выполнено'},
        {value: '1', text: 'Выполнено'},
      ],
      priority: [
        {value: '0', text: 'Низкий'},
        {value: '1', text: 'Средний'},
        {value: '2', text: 'Высокий'},
      ],
    }
  },
  computed: {
    allSelected() {
      return this.$store.state.user.managers && this.filters.user_id.length === this.$store.state.user.managers.length
    },
    someSelected() {
      return this.filters.user_id.length > 0 && !this.allSelected
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box'
      if (this.someSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  watch: {
    '$store.state.user': function (user) {
      console.log('$store.state.user', {...user});
      if (user.managers && user.managers.map((user) => user.n_id).includes(user.n_id)) {
        this.filters.user_id = [user.n_id];
      }
      // this.loadFilters();
      this.getTasks();
    }
  },
  methods: {
    emptyDate() {
      console.log('emptyDate');
      this.filters.date = [];
      this.getTasks();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.filters.user_id = []
        } else {
          this.filters.user_id = this.$store.state.user.managers.map((user) => user.n_id).slice()
        }
        this.getTasks();
      })
    },
    getTasks(reloadTable = true) {
      console.log('getTasks');
      if (reloadTable) {
        // this.$root.saveData('tasks', 'filters', this.filters, true);
        // console.log('saved filter', JSON.parse(JSON.stringify(this.filters)));
      }
      this.$store.state.server.request('task/get', this.filters, (data) => {
        this.loading_task = false;
        this.rows = data.response;
        if (!reloadTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/tasks') this.getTasks(false)
        }, 5000)
      }, (data) => {
        this.loading_task = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
        if (!reloadTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/tasks') this.getTasks(false)
        }, 5000)
      })
    },
    editTask(task_id = null) {
      this.$eventBus.$emit('openTask', {id: task_id});
    },
    showFullComment(task_id, task_comment) {
      if (task_comment.length > 15) {
        this.$refs[task_id].style.display = 'block'
      }
    },
    closeFullComment(task_id) {
      this.$refs[task_id].style.display = 'none'
    },
    loadFilters() {
      let filters = this.$root.getData('tasks', 'filters', true);
      console.log('loadFilters', JSON.parse(JSON.stringify(filters)));
      if (filters) this.filters = filters;
    }
  },
  mounted() {
    this.getTasks(false)
  },
  created() {
    if (this.$store.state.user.managers && this.$store.state.user.managers.map((user) => user.n_id).includes(this.$store.state.user.n_id)) {
      this.filters.user_id = [this.$store.state.user.n_id];
    }
    // if (this.$store.state.user.n_id) this.loadFilters();
    this.$eventBus.$on('editTask', () => {
      this.getTasks();
    })
  },
  destroyed() {
    this.$eventBus.$off('editTask');
  }
}
</script>

<style scoped>

</style>
